export class SetOrganizationsLoading {
  static readonly type = '[Loading] SetOrganizationsLoading';
  constructor(public value: boolean) {}
}

// TDB deprecated? Active dialog loading does not need to be globally stored in the store
export class SetActiveDialogLoading {
  static readonly type = '[Loading] SetActiveDialogLoading';
  constructor(public value: boolean) {}
}

export class SetUserEmailUpdating {
  static readonly type = '[Loading] SetUserEmailUpdating';
  constructor(public value: boolean) {}
}

export class SetOrganizationUsersLoading {
  static readonly type = '[Loading] SetOrganizationUsersLoading';
  constructor(public value: boolean) {}
}
