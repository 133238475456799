import { inject, Injectable, signal } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  reload,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GoogleAuthProvider } from 'firebase/auth';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, finalize, map, Observable, take } from 'rxjs';
import { LoadingService } from './loading.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { SetUserEmailUpdating } from '../store/loading/loading.action';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth);
  private router = inject(Router);
  private http = inject(HttpClient);
  private store = inject(Store);
  private toastr = inject(ToastrService);
  private loadingService = inject(LoadingService);

  private userSubject$: BehaviorSubject<any> = new BehaviorSubject(null);
  private checkingAuthState$ = signal(true);

  constructor() {
    this.loadingService.loading = true;
    onAuthStateChanged(this.auth, (user: any) => {
      this.userSubject$.next(user);
      if (this.checkingAuthState$()) {
        this.checkingAuthState$.set(false);
        this.loadingService.loading = false;
      }
    });
  }

  get user(): Observable<any> {
    return this.userSubject$.asObservable();
  }

  get checkingAuthState() {
    return environment.app ? this.checkingAuthState$ : signal(false);
  }

  get emailVerified(): Observable<boolean> {
    return this.user.pipe(map((user) => user?.emailVerified));
  }

  async signIn(email: string, password: string) {
    this.loadingService.loading = true;
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      console.log(userCredential);
      await this.router.navigate(['/']);
    } catch (error: any) {
      console.error(error);
      const errorCode = error.code;
      switch (errorCode) {
        case 'auth/invalid-credential':
          this.toastr.error(
            'E-Mail/Passwort falsch - hast du bereits ein Konto?',
          );
          await this.router.navigate(['/auth/login']);
          break;
        default:
          this.toastr.error('Fehler! Bitte versuche es später erneut');
          break;
      }
    } finally {
      this.loadingService.loading = false;
    }
  }

  async signUp(email: string, password: string) {
    this.loadingService.loading = true;
    try {
      await createUserWithEmailAndPassword(this.auth, email, password).finally(
        () => this.updateFirestoreUserEmailWithFireAuthEmail(),
      );
    } catch (error: any) {
      console.error(error);
      const errorCode = error.code;
      switch (errorCode) {
        case 'auth/email-already-in-use':
          this.toastr.error('E-Mail existiert bereits');
          break;
        case 'auth/invalid-email':
          this.toastr.error('E-Mail ungültig');
          break;
        default:
          this.toastr.error('Fehler! Bitte versuche es später erneut');
          break;
      }
    } finally {
      await this.sendVerificationEmail();
      this.loadingService.loading = false;
    }
  }

  updateFirestoreUserEmailWithFireAuthEmail() {
    this.store.dispatch(new SetUserEmailUpdating(true));
    this.http
      .get(`${environment.domains.functionStripe}/user/updateEmail`)
      .pipe(
        take(1),
        finalize(() => this.store.dispatch(new SetUserEmailUpdating(false))),
      )
      .subscribe();
  }

  signInWithGoogle() {
    this.checkingAuthState$.set(true);
    const provider = new GoogleAuthProvider();

    // optionale scopes
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    signInWithPopup(this.auth, provider)
      .then(() => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential && credential.accessToken;

        this.router.navigate(['/']);
      })
      .catch((error: any) => {
        console.error(error);
        this.checkingAuthState$.set(false);
        this.toastr.error(
          'Anmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
        );
      });
  }

  signOut() {
    this.checkingAuthState$.set(true);
    signOut(this.auth)
      .then(() => {
        this.router.navigate(['/auth']);
      })
      .catch((error: any) => {
        console.error(error);
        this.toastr.error(
          'Abmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
        );
      });
  }

  async resetPassword(email: string) {
    this.loadingService.loading = true;
    try {
      await sendPasswordResetEmail(this.auth, email);
      this.toastr.success('E-Mail zum Zurücksetzen des Passworts gesendet');
    } catch (error) {
      this.toastr.error('Fehler! Bitte versuche es später erneut');
    } finally {
      this.router
        .navigate(['/auth'])
        .then(() => (this.loadingService.loading = false));
    }
  }

  async sendVerificationEmail(): Promise<void> {
    const user = this.auth.currentUser;
    if (user) {
      this.loadingService.loading = true;
      sendEmailVerification(user)
        .then(() => this.toastr.success('E-Mail zum Bestätigen gesendet'))
        .catch(() =>
          this.toastr.error('Fehler! Bitte versuche es später erneut'),
        )
        .finally(() => (this.loadingService.loading = false));
    }
  }

  async refreshUser(): Promise<void> {
    this.loadingService.loading = true;
    if (this.userSubject$.value) {
      try {
        await reload(this.userSubject$.value);
        this.userSubject$.next(this.userSubject$.value);
        this.toastr.success('Benutzer aktualisiert');
      } catch (error) {
        this.toastr.error('Fehler! Bitte versuche es später erneut');
      } finally {
        this.loadingService.loading = false;
      }
    }
  }
}
