export const environment = {
  production: false,
  app: true,
  domains: {
    landing: 'https://dev.schriftfuehrer.at',
    app: '',
    // use this for local function testing
    // functionStripe: 'http://127.0.0.1:5001/schriftfu-dev/europe-west3/api',
    functionStripe: 'https://api-f4eglra76q-ey.a.run.app',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB3SUvnFUJVzlg4eb2UnOjNFKCzOQQ8rDg',
    authDomain: 'schriftfu-dev.firebaseapp.com',
    projectId: 'schriftfu-dev',
    storageBucket: 'schriftfu-dev.appspot.com',
    messagingSenderId: '473112782076',
    appId: '1:473112782076:web:184e2ba5a93205b53fa379',
    measurementId: 'G-40DCSHLM5T',
  },
  features: {
    pagination: false,
    connectLandingAndApp: true,
  },
  planLimitsByGrantingLevel: {
    0: {
      maxMembers: 0,
      maxEventsPerYear: 0,
      maxCustomMemberFields: 0,
      maxCustomEventFields: 0,
      maxEventTypes: 0,
    },
    1: {
      maxMembers: 10,
      maxEventsPerYear: 5,
      maxCustomMemberFields: 2,
      maxCustomEventFields: 1,
      maxEventTypes: 3,
    },
    2: {
      maxMembers: 20,
      maxEventsPerYear: 10,
      maxCustomMemberFields: 4,
      maxCustomEventFields: 3,
      maxEventTypes: 5,
    },
    3: {
      maxMembers: 30,
      maxEventsPerYear: 20,
      maxCustomMemberFields: 5,
      maxCustomEventFields: 5,
      maxEventTypes: 5,
    },
  },
};
