import {
  computed,
  inject,
  Injectable,
  PLATFORM_ID,
  Signal,
  signal,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
/**
 * todo LoadingService is deprecated, use loadingState instead!
 */
export class LoadingService {
  private willSetRouteSwitchInProgress = false;
  private router = inject(Router);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private loadingInstances$ = signal(0);
  private loading$ = computed(() => this.loadingInstances$() > 0);

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.willSetRouteSwitchInProgress = true;

        // Only after 300 ms we show the spinner to avoid flickering
        this.isBrowser &&
          setTimeout(() => {
            if (this.willSetRouteSwitchInProgress) {
              this.addLoadingInstance();
              this.willSetRouteSwitchInProgress = false;
            }
          }, 300);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationSkipped
      ) {
        if (!this.willSetRouteSwitchInProgress) {
          this.loadingInstances$.set(0);
        }
        this.willSetRouteSwitchInProgress = false;
      }
    });
  }

  get loading(): Signal<boolean> {
    return this.loading$;
  }

  set loading(value: boolean) {
    value ? this.addLoadingInstance() : this.removeLoadingInstance();
  }

  private addLoadingInstance() {
    this.loadingInstances$.set(this.loadingInstances$() + 1);
  }

  private removeLoadingInstance() {
    this.loadingInstances$() > 0 &&
      this.loadingInstances$.set(this.loadingInstances$() - 1);
  }
}
